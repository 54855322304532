<template>
<div class="default">
    <div style="margin-left: 4px;overflow-y: scroll;width: calc(100% - 8px);height: calc(100% - 10px);margin-top: 10px;margin-bottom: 10px;overflow-x: hidden;">
        <div style="width:100%;height:16px;"></div>
        <div class="row-content ">
            <div class="row-title">
                <div class="row-title-left">
                    <span style="font-weight:600;font-size: 16px;color: #606266;">当前数据&nbsp;&nbsp;</span>
                    <span style="font-size: 12px;color: rgba(96, 98, 102, 0.5);">数据更新至&nbsp;{{newCustomerCounterMap.dataTime}}</span> 
                </div>
                <div class="row-title-right">
                    <el-button type="primary" @click="dialogVisible = true">编辑</el-button> 
                </div>
            </div>
            <div class="row-data" style="height: 160px;">
                <div class="row-data-item" v-for="(item,index) in newCustomerCounterMap.list" :key="index" 
                    v-bind:style="index < (newCustomerCounterMap.list.length - 1)?'border-right: 1px solid rgba(96, 98, 102, 0.5);width:' + (100 / newCustomerCounterMap.list.length) + '%;':'border:0px;width:' + (100 / newCustomerCounterMap.list.length) + '%;'">
                    <div class="row-data-item-title">{{item.title}}</div>
                    <div class="row-data-item-total">{{formatNum(item.total)}}</div>
                    <div class="row-data-item-num" v-show="item.day > 0">
                        <div>
                            <span>日&nbsp;</span><span style="color:#F56C6C;">{{(item.day * 100).toFixed(2)}}%&nbsp;<el-icon><Top /></el-icon></span>
                        </div>
                    </div>
                    <div class="row-data-item-num" v-show="item.day <= 0">
                        <div>
                            <span>日&nbsp;</span><span style="color:#70B603;">{{(-item.day * 100).toFixed(2)}}%&nbsp;<el-icon><Bottom /></el-icon></span>
                        </div>
                    </div>
                    <div class="row-data-item-num" v-show="item.week > 0">
                        <div>
                            <span>周&nbsp;</span><span style="color:#F56C6C;">{{(item.week * 100).toFixed(2)}}%&nbsp;<el-icon><Top /></el-icon></span>
                        </div>
                    </div>
                    <div class="row-data-item-num" v-show="item.week <= 0">
                        <div>
                            <span>周&nbsp;</span><span style="color:#70B603;">{{(-item.week * 100).toFixed(2)}}%&nbsp;<el-icon><Bottom /></el-icon></span>
                        </div>
                    </div>
                    <div class="row-data-item-num" v-show="item.month > 0">
                        <div>
                            <span>月&nbsp;</span><span style="color:#F56C6C;">{{(item.month * 100).toFixed(2)}}%&nbsp;<el-icon><Top /></el-icon></span>
                        </div>
                    </div>
                    <div class="row-data-item-num" v-show="item.month <= 0">
                        <div>
                            <span>月&nbsp;</span><span style="color:#70B603;">{{(-item.month * 100).toFixed(2)}}%&nbsp;<el-icon><Bottom /></el-icon></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width:100%;height:16px;"></div>
        <div class="row-content">
            <div class="row-title">
                <div class="row-title-left" style="width:100% !important">
                    <span style="font-weight:600;font-size: 16px;color: #606266;">实时互动量&nbsp;&nbsp;</span>
                    <span style="font-size: 12px;color: rgba(96, 98, 102, 0.5);">发友分享、极限挑战、点赞、评论行为数量合计；数据粒度，按<span style="color:#0299B5;">60</span>分钟聚合。</span>
                </div> 
            </div>
            <div class="row-data" style="height:340px;margin-top:-28px;">
                <div id="activeChart" ref="activeChart" style="width:100%;height:100%;"></div>
            </div> 
            <div class="row-title">
                <div class="row-title-left" style="width:100% !important;margin-top:20px;padding-left:60px;">
                    <span style="font-size: 12px;color: rgba(96, 98, 102, 0.5);">发友分享、极限挑战、点赞、评论行为数量合计；数据粒度，按<span style="color:#0299B5;">天</span>聚合。</span>
                </div> 
            </div>
            <div class="row-data" style="height:340px;margin-top:-16px;">
                <div id="activeDayChart" ref="activeDayChart" style="width:100%;height:100%;"></div>
            </div> 
            <div class="row-title">
                <div class="row-title-left" style="width:100% !important;margin-top:20px;padding-left:60px;">
                    <span style="font-size: 12px;color: rgba(96, 98, 102, 0.5);">发友分享、极限挑战、点赞、评论行为数量合计；数据粒度，按<span style="color:#0299B5;">月</span>聚合。</span>
                </div> 
            </div>
            <div class="row-data" style="height:340px;margin-top:-16px;">
                <div id="activeMonthChart" ref="activeMonthChart" style="width:100%;height:100%;"></div>
            </div> 
        </div>
        <div style="width:100%;height:16px;"></div>
        <div class="row-content">
            <div class="row-title">
                <div class="row-title-left">
                    <span style="font-weight:600;font-size: 16px;color: #606266;">用户概括</span> 
                </div> 
            </div>
            <div class="row-data" style="height:400px;">
                <div style="width:33%;">
                    <div class="row-data-title">性别分布</div>
                    <div class="row-data-item">
                        <div ref="genderChart" style="width:100%;height:100%;"></div>
                    </div>
                </div>
                <div style="width:33%;">
                    <div class="row-data-title">TOP10&nbsp;省/市分布</div>
                    <div class="row-data-item">
                        <div ref="cityChart" style="width:100%;height:100%;"></div>
                    </div>
                </div>
                <div style="width:33%;">
                    <div class="row-data-title">TOP10&nbsp;等级分布</div>
                    <div class="row-data-item" style="border:0px;">
                        <div ref="levelChart" style="width:100%;height:100%;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width:100%;height:16px;"></div>
    </div>
    <!-- ---------------------------------------40%-------添加或者编辑弹窗---------------------------------------------- -->
    <el-dialog v-model="dialogVisible" width="600px" :show-close="false" :before-close="handleClose" top="30vh"> 
        <template #header="{titleId}">
            <div class="my-header">
                <span :id="titleId" class="titleClass">添加/编辑数据项</span>
                <span class="titleClass" style="font-size: 12px;color: rgba(96, 98, 102, 0.5);margin-left:115px;">&nbsp;&nbsp;可自定义展示个人关注数据项</span>
            </div>
        </template>
        <div class="dialog-form">
            <el-row v-for="(item,index) in customerConfigs" :key="index">
                <el-col :span="24">
                    <div style="width:100%;text-align:left;font-family:'PingFang SC';font-style: normal;font-size: 14px;font-weight: 600;color: #606266;">{{item.group}}</div>
                    <div style="width:100%;margin-top:10px;margin-bottom:20px;text-align: left;display:flex;">
                        <div style="font-family:'PingFang SC';font-style: normal;font-size: 12px;color: #606266;width: 120px;" v-for="subItem in item.children" :key="subItem.id">
                            <el-checkbox :label="subItem.name" v-model="customerSelectMap[subItem.id]" @change="checkboxSelect(subItem.id)"/>
                        </div>
                    </div>
                </el-col>   
            </el-row> 
        </div>
        <template #footer>
            <div class="dialog-footer" style="display:flex;">
                <div style="font-family:'PingFang SC';font-style: normal;font-size: 12px;color: rgba(96, 98, 102, 0.5);width:200px;text-align:left;padding-left: 20px;">
                    还可选择<span style="color:#0299B5;">&nbsp;{{customerSelectNum - useCustomerSelectNum}}&nbsp;</span>个数据项
                </div>
                <div style="width:60%;text-align:left;">
                    <el-button type="primary" @click="saveEdit()">保存</el-button>
                    <el-button @click="dialogVisible = false;">取消</el-button>
                </div>
            </div>
        </template>
    </el-dialog>
</div> 
</template>
<script> 
import {asyncPostLoading,asyncPost} from '../api/request';
import {formatNumber} from '../api/Tools'
import {getGenderCounterData,getCityCounterData,getLevelCounterData,getActiveCounterData,getActiveCounterDayOfDay,getActiveCounterOfMonth} from '../api/console-echarts-data'
import {Top,Bottom} from '@element-plus/icons-vue'

// var myDate = new Date();
// var myDateS = myDate.getSeconds()*1000;
// var myDateMs = myDate.getMilliseconds();//获取到毫秒以减少误差
// setTimeout(function () {
//     //函数
//     activeCounter();
// },3600000-myDateS-myDateMs);

export default {  
    setup(){ 
        return {Top,Bottom}
    },
    components:{
        Top,Bottom
    },
    data:function(){
        return {
            dialogVisible: false,              //编辑弹框
            customerSelectNum: 6,              //可以选择类型个数
            useCustomerSelectNum: 0,            //已经选择的个数
            customerSelectMap: {},              //已经选择的数据
            customerConfigs: [],                //客户统计数据配置
            newCustomerCounterMap: {},         //新增用户统计 
            timer:"",                           //定时器ID

            // activeChart: null,                  //按小时图
            // activeDayChart: null,               //按天的图
            // activeMonthChart: null,             //按月的图
        }
    },
    methods:{
        //格式化数字
        formatNum(num){
            return formatNumber(num,"",0);
        },
        //弹窗其他区域点击不关闭
        handleClose(done){ },
        //复选框点击，用来过滤可以选择的个数
        checkboxSelect(id){
            
            let num = 0;
            for(let k in this.customerSelectMap){
                if(this.customerSelectMap[k]) {
                    num ++;
                } 
            }
            if(num > this.customerSelectNum){
                this.customerSelectMap[id] = false;
                this.useCustomerSelectNum = num - 1;
                this.$message.warning("自定义展示个人关注数据项最多可以选择" + this.customerSelectNum + "个");
            }else{
                this.useCustomerSelectNum = num;
            }
        },
        //保存数据
        saveEdit(){
            let idArray = [];
            for(let k in this.customerSelectMap){
                if(this.customerSelectMap[k]) {
                    idArray.push(k);
                }
            }
            let ids = idArray.join(",");
            asyncPostLoading(this.$customerURL + "/console/editConsoleConfig",{configs:ids},res => {
                if(res.data.head.st != 0){
                    this.$message.error(res.data.head.msg);
                    return;
                }
                this.dialogVisible = false;
                this.newCustomerCounterMap = res.data.body;
                this.loadCustomerDayWeekMonthCounter();
                this.downloadCustomerConfig();
            },err => {
                this.$ElMessage.error(err); 
            });
        },
        //显示用户的日、周、月新增
        loadCustomerDayWeekMonthCounter(){
            asyncPost(this.$customerURL + "/console/queryConsoleCustomerCounter",{},res => {
                if(res.data.head.st != 0){
                    this.$message.error(res.data.head.msg);
                    return;
                }
                this.newCustomerCounterMap = res.data.body;
            },err => {
                this.$ElMessage.error(err); 
            });
        },
        //下载配置数据
        downloadCustomerConfig(){
            asyncPostLoading(this.$customerURL + "/console/queryConsoleConfig",{},res => {
                if(res.data.head.st != 0){
                    this.$message.error(res.data.head.msg);
                    return;
                }
                this.customerConfigs = res.data.body.configs;
                let usedList = res.data.body.usedConfigs;
                for(let i = 0;i<usedList.length;i++){
                    this.customerSelectMap[usedList[i].config] = true;
                }
                this.checkboxSelect(-1);
            },err => {
                this.$ElMessage.error(err); 
            });
        },
        /**************************************************饼图柱状图报表**************************************************/
        //性别分布
        genderCounter(){ 
            let genderCounterEcharts = this.$echarts.init(this.$refs.genderChart);
            asyncPost(this.$customerURL + "/console/queryConsoleUsersGender",{},res => {
                if(res.data.head.st != 0){
                    funError(res.data.head.msg);
                    return;
                }
                let genderCounterData = getGenderCounterData(res.data.body);
                genderCounterEcharts.setOption(genderCounterData);
            },err => {
                this.$ElMessage.error(err); 
            });
        },
        //城市数据分布
        cityCounter(){
            let cityCounterEcharts = this.$echarts.init(this.$refs.cityChart);
            asyncPost(this.$customerURL + "/console/queryConsoleUsersCities",{},res => {
                if(res.data.head.st != 0){
                    funError(res.data.head.msg);
                    return;
                }
                let cityCounterData = getCityCounterData(res.data.body);
                cityCounterEcharts.setOption(cityCounterData);
            },err => {
                this.$ElMessage.error(err); 
            });
        },
        //客户等级分布
        levelCouner(){
            let levelCounterEcharts = this.$echarts.init(this.$refs.levelChart);
            asyncPost(this.$customerURL + "/console/queryConsoleUsersLevel",{},res => {
                if(res.data.head.st != 0){
                    funError(res.data.head.msg);
                    return;
                }
                let levelCounterData = getLevelCounterData(res.data.body);
                levelCounterEcharts.setOption(levelCounterData);
            },err => {
                this.$ElMessage.error(err); 
            });
        },
        //吐槽，点赞，评论折线图 --------- 按小时
        activeCounter(){
            // if(this.activeChart) this.activeChart.dispose();
            // this.activeChart = this.$echarts.init(this.$refs.activeChart);
            let activeChart = this.$echarts.init(this.$refs.activeChart);
            asyncPost(this.$customerURL + "/console/queryConsoleVolumeInteraction",{},res => {
                if(res.data.head.st != 0){
                    funError(res.data.head.msg);
                    return;
                }
                
                let todayDatas = [];
                let todayStr = res.data.body.today_count;
                if(todayStr == undefined || todayStr == null){
                    todayStr = "";
                }
                let array = todayStr.split(",");
                for(let i = 0;i<array.length;i++){
                    todayDatas.push(parseInt(array[i]));
                }

                let yesterData = [];
                let yesterStr = res.data.body.yester_count;
                if(yesterStr == undefined || yesterStr == null){
                    yesterStr = "";
                }
                array = yesterStr.split(",");
                for(let i = 0;i<array.length;i++){
                    yesterData.push(parseInt(array[i]));
                }
                let echartsData = {
                    today_count: todayDatas,
                    yester_count: yesterData
                };
                let todayLineColor = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {offset: 0, color: 'rgb(128, 255, 165)' },
                    {offset: 1, color: 'rgb(1, 191, 236)' }
                ]);
                let yesterLineColor = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {offset: 0, color: 'rgb(0, 221, 255)' },
                    {offset: 1, color: 'rgb(77, 119, 255)' }
                ]);

                let activeCounterData = getActiveCounterData(echartsData,todayLineColor,yesterLineColor);

                activeChart.setOption(activeCounterData);
            },err => {
                this.$ElMessage.error(err); 
            });
        },
        //吐槽，点赞，评论折线图 --------- 按天
        activeCounterOfDay(){
            //if(this.activeDayChart) this.activeDayChart.dispose();
            //this.activeDayChart = this.$echarts.init(this.$refs.activeDayChart);
            let activeDayChart = this.$echarts.init(this.$refs.activeDayChart);
            asyncPost(this.$customerURL + "/console/queryConsoleVolumeInteractionOfDay",{},res => {
                if(res.data.head.st != 0){
                    funError(res.data.head.msg);
                    return;
                }
                
                let todayDatas = [];
                let todayStr = res.data.body.today_count;
                if(todayStr == undefined || todayStr == null){
                    todayStr = "";
                }
                let array = todayStr.split(",");
                for(let i = 0;i<array.length;i++){
                    todayDatas.push(parseInt(array[i]));
                }

                let yesterData = [];
                let yesterStr = res.data.body.yester_count;
                if(yesterStr == undefined || yesterStr == null){
                    yesterStr = "";
                }
                array = yesterStr.split(",");
                for(let i = 0;i<array.length;i++){
                    yesterData.push(parseInt(array[i]));
                }
                let echartsData = {
                    today_count: todayDatas,
                    yester_count: yesterData
                };
                let todayLineColor = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {offset: 0, color: 'rgb(128, 255, 165)' },
                    {offset: 1, color: 'rgb(1, 191, 236)' }
                ]);
                let yesterLineColor = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {offset: 0, color: 'rgb(0, 221, 255)' },
                    {offset: 1, color: 'rgb(77, 119, 255)' }
                ]);

                let activeCounterData = getActiveCounterDayOfDay(echartsData,todayLineColor,yesterLineColor);

                activeDayChart.setOption(activeCounterData);
            },err => {
                this.$ElMessage.error(err); 
            });
        },
        //吐槽，点赞，评论折线图 --------- 按月
        activeCounterOfMonth(){
            //if(this.activeMonthChart) this.activeMonthChart.dispose();
            //this.activeMonthChart = this.$echarts.init(this.$refs.activeMonthChart);
            let activeMonthChart = this.$echarts.init(this.$refs.activeMonthChart);
            asyncPost(this.$customerURL + "/console/queryConsoleVolumeInteractionOfMonth",{},res => {
                if(res.data.head.st != 0){
                    funError(res.data.head.msg);
                    return;
                }
                
                let todayDatas = [];
                let todayStr = res.data.body.today_count;
                if(todayStr == undefined || todayStr == null){
                    todayStr = "";
                }
                let array = todayStr.split(",");
                for(let i = 0;i<array.length;i++){
                    todayDatas.push(parseInt(array[i]));
                }

                let yesterData = [];
                let yesterStr = res.data.body.yester_count;
                if(yesterStr == undefined || yesterStr == null){
                    yesterStr = "";
                }
                array = yesterStr.split(",");
                for(let i = 0;i<array.length;i++){
                    yesterData.push(parseInt(array[i]));
                }
                let echartsData = {
                    today_count: todayDatas,
                    yester_count: yesterData
                };
                let todayLineColor = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {offset: 0, color: 'rgb(128, 255, 165)' },
                    {offset: 1, color: 'rgb(1, 191, 236)' }
                ]);
                let yesterLineColor = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {offset: 0, color: 'rgb(0, 221, 255)' },
                    {offset: 1, color: 'rgb(77, 119, 255)' }
                ]);

                let activeCounterData = getActiveCounterOfMonth(echartsData,todayLineColor,yesterLineColor);

                activeMonthChart.setOption(activeCounterData);
            },err => {
                this.$ElMessage.error(err); 
            });
        },
        //设置图形大小
        resizeEcharts(){ 
            let genderCounterEcharts = this.$echarts.init(this.$refs.genderChart);
            genderCounterEcharts && genderCounterEcharts.resize();
            let cityCounterEcharts = this.$echarts.init(this.$refs.cityChart);
            cityCounterEcharts && cityCounterEcharts.resize();
            let levelCounterEcharts = this.$echarts.init(this.$refs.levelChart);
            levelCounterEcharts && levelCounterEcharts.resize();
            //按小时
            let activeCounterEchart = this.$echarts.init(this.$refs.activeChart);
            activeCounterEchart && activeCounterEchart.resize();
            //this.activeChart && this.activeChart.resize();
           
            //按天
            let activeCounterOfDayEchart = this.$echarts.init(this.$refs.activeDayChart);
            activeCounterOfDayEchart && activeCounterOfDayEchart.resize();
            //this.activeDayChart && this.activeDayChart.resize();
           
            //按月
            let activeCounterOfMonthEchart = this.$echarts.init(this.$refs.activeMonthChart);
            activeCounterOfMonthEchart && activeCounterOfMonthEchart.resize();
            //this.activeMonthChart && this.activeMonthChart.resize();
        },
    }, 
    mounted(){
        this.loadCustomerDayWeekMonthCounter();
        this.downloadCustomerConfig(); 

        this.genderCounter();
        this.cityCounter();
        this.levelCouner();
        this.activeCounter();
        this.activeCounterOfDay();
        this.activeCounterOfMonth();

        //定时器，用来定时刷新图标数据
        // this.timer = setInterval(() =>{
            
        //     this.genderCounter(); 
        //     this.cityCounter();
        //     this.levelCouner();
        //     this.activeCounter();
        //     this.activeCounterOfDay();
        //     this.activeCounterOfMonth();
        //     this.loadCustomerDayWeekMonthCounter(); //第行数据加载
        // },5 * 60 * 1000);

        window.onresize = () => {
            return (() => {
                this.resizeEcharts();
            })();
        };
    },
    beforeDestroy(){
        clearInterval(this.timer); //清除定时器
    },
    created(){
        //window.activeCounter= this.activeCounter  // 将vue中要被调用的方法定义成 window 全局方法
    }
}
</script>

<style  scoped>
/* **********************************搜索和操作区域样式，公用********************************** */
.default{
    margin:0;
    padding: 10px 0px 10px 0px;
    width:100%;
    height:calc(100% - 20px);
    background: #FFFFFF; 
    border-radius: 5px; 
}
.row-content{
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 2px 2px 3px 2px rgba(96, 98, 102, 0.2)
}
.row-title{
    font-family: 'PingFang SC';
    font-style: normal;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    height: 50px;
    line-height: 50px;
}
.row-title-left{
    width: calc(50% - 20px);
    text-align: left;
}
.row-title-right{
    width: calc(50% - 20px);
    text-align: right;
}
.row-data{
    display: flex;
    margin-left: 40px;
    width: calc(100% - 80px);
    margin-top: 20px;
}
.row-data-item{
    border-right: 1px solid rgba(96, 98, 102, 0.5);
    height: 90%;
    text-align: center;
}
.row-data-item-title{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: rgba(96, 98, 102, 0.7);
    text-align: center;
    height: 25px;

    width: 120px;
    margin: auto;
}
.row-data-item-total{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #606266;
    height: 45px;
    line-height: 45px;

    width: 90px;
    margin: auto;
}
.row-data-item-num{
    font-family: 'PingFang SC';
    font-style: normal;
    color: rgba(96, 98, 102, 0.7);
    /* font-weight: 600; */
    font-size: 12px;
    height: 25px;
    line-height: 25px;

    width: 100%;
} 
.row-data-item-num div{
    width:82px;
    text-align:left;
    margin: auto;
}
.row-data-title{
    width: 100%;
    text-align: left;
    font-family: 'PingFang SC';
    font-style: normal;
    font-size: 14px;

    color: #606266;
}

/* *****************************滚动条样式********************************* */
::-webkit-scrollbar{
    width:6px;
    /* height:5px; */
    background-color:#F5F5F5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track{
    /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
    /* border-radius:10px; */
    background-color:#F5F5F5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb{
    border-radius:2px;
    /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3); */
    /* background-color:rgba(2, 153, 181,0.8); */
    background-color:rgba(200, 201, 204,0.8);
    
} 
/* 鼠标悬停 */
::-webkit-scrollbar-thumb:hover{
    cursor: pointer;
}
</style>