<template>
    <div class="left-menu"> 
        <el-menu
            default-active="onRoutes"
            class="el-menu-vertical-demo"
            :collapse="isCollapse" 
        >
        <!-- :unique-opened="true" -->
            <el-menu-item class="single-view" @click="onSingleMenuClick('console')">
                <el-icon><Monitor /></el-icon>
                <template #title>工作台</template>
            </el-menu-item>
            <el-sub-menu v-for="item in menuTree" :key="item.id" :index="item.id">
                <template #title>
                    <!-- <el-icon><Location/></el-icon> -->
                    <component class="icons" :is="item.smallImageUrl"></component>
                    <span>{{item.permission}}</span>
                </template> 
                <el-menu-item v-for="subItem in item.children" :key="subItem.id" :index="subItem.id" @click="onMenuClick(subItem)">
                    {{subItem.permission}}
                </el-menu-item> 
            </el-sub-menu> 
        </el-menu>
    </div>
</template>
<script>
import { asyncPostLoading } from '../api/request';
// import {Base64} from 'js-base64'
import UserStorage from '../api/UserStorage'
import RouteData from '../api/RouteData'
import 
{
    AddLocation,Aim,AlarmClock,Apple,ArrowDown,ArrowDownBold,ArrowLeft
    ,ArrowLeftBold,ArrowRight,ArrowRightBold,ArrowUp,ArrowUpBold,Avatar,Back
    ,Baseball,Basketball,Bell,BellFilled,Bicycle,Bottom,BottomLeft
    ,BottomRight,Bowl,Box,Briefcase,Brush,BrushFilled,Burger
    ,Calendar,Camera,CameraFilled,CaretBottom,CaretLeft,CaretRight,CaretTop
    ,Cellphone,ChatDotRound,ChatDotSquare,ChatLineRound,ChatLineSquare,ChatRound,ChatSquare
    ,Check,Checked,Cherry,Chicken,CircleCheck,CircleCheckFilled,CircleClose
    ,CircleCloseFilled,CirclePlus,CirclePlusFilled,Clock,Close,CloseBold,Cloudy
    ,Coffee,CoffeeCup,Coin,ColdDrink,Collection,CollectionTag,Comment
    ,Compass,Connection,Coordinate,CopyDocument,Cpu,CreditCard,Crop
    ,DArrowLeft,DArrowRight,DCaret,DataAnalysis,DataBoard,DataLine,Delete
    ,DeleteFilled,DeleteLocation,Dessert,Discount,Dish,DishDot,Document
    ,DocumentAdd,DocumentChecked,DocumentCopy,DocumentDelete,DocumentRemove,Download,Drizzling
    ,Edit,EditPen,Eleme,ElemeFilled,ElementPlus,Expand,Failed
    ,Female,Files,Film,Filter,Finished,FirstAidKit,Flag
    ,Fold,Folder,FolderAdd,FolderChecked,FolderDelete,FolderOpened,FolderRemove
    ,Food,Football,ForkSpoon,Fries,FullScreen,Goblet,GobletFull
    ,GobletSquare,GobletSquareFull,Goods,GoodsFilled,Grape,Grid,Guide
    ,Headset,Help,HelpFilled,Hide,Histogram,HomeFilled,HotWater
    ,House,IceCream,IceCreamRound,IceCreamSquare,IceDrink,IceTea,InfoFilled
    ,Iphone,Key,KnifeFork,Lightning,Link,List,Loading
    ,Location,LocationFilled,LocationInformation,Lock,Lollipop,MagicStick,Magnet
    ,Male,Management,MapLocation,Medal,Menu,Message,MessageBox
    ,Mic,Microphone,MilkTea,Minus,Money,Monitor,Moon
    ,MoonNight,More,MoreFilled,MostlyCloudy,Mouse,Mug,Mute
    ,MuteNotification,NoSmoking,Notebook,Notification,Odometer,OfficeBuilding,Open
    ,Operation,Opportunity,Orange,Paperclip,PartlyCloudy,Pear,Phone
    ,PhoneFilled,Picture,PictureFilled,PictureRounded,PieChart,Place,Platform
    ,Plus,Pointer,Position,Postcard,Pouring,Present,PriceTag
    ,Printer,Promotion,QuestionFilled,Rank,Reading,ReadingLamp,Refresh
    ,RefreshLeft,RefreshRight,Refrigerator,Remove,RemoveFilled,Right,ScaleToOriginal
    ,School,Scissor,Search,Select,Sell,SemiSelect,Service
    ,SetUp,Setting,Share,Ship,Shop,ShoppingBag,ShoppingCart
    ,ShoppingCartFull,Smoking,Soccer,SoldOut,Sort,SortDown,SortUp
    ,Stamp,Star,StarFilled,Stopwatch,SuccessFilled,Sugar,Suitcase
    ,Sunny,Sunrise,Sunset,Switch,SwitchButton,TakeawayBox,Ticket
    ,Tickets,Timer,ToiletPaper,Tools,Top,TopLeft,TopRight
    ,TrendCharts,Trophy,TurnOff,Umbrella,Unlock,Upload,UploadFilled
    ,User,UserFilled,Van,VideoCamera,VideoCameraFilled,VideoPause,VideoPlay
    ,View,Wallet,WalletFilled,Warning,WarningFilled,Watch,Watermelon
    ,WindPower,ZoomIn,ZoomOut
} 
from '@element-plus/icons-vue'
export default { 
    components:{
        AddLocation,Aim,AlarmClock,Apple,ArrowDown,ArrowDownBold,ArrowLeft
        ,ArrowLeftBold,ArrowRight,ArrowRightBold,ArrowUp,ArrowUpBold,Avatar,Back
        ,Baseball,Basketball,Bell,BellFilled,Bicycle,Bottom,BottomLeft
        ,BottomRight,Bowl,Box,Briefcase,Brush,BrushFilled,Burger
        ,Calendar,Camera,CameraFilled,CaretBottom,CaretLeft,CaretRight,CaretTop
        ,Cellphone,ChatDotRound,ChatDotSquare,ChatLineRound,ChatLineSquare,ChatRound,ChatSquare
        ,Check,Checked,Cherry,Chicken,CircleCheck,CircleCheckFilled,CircleClose
        ,CircleCloseFilled,CirclePlus,CirclePlusFilled,Clock,Close,CloseBold,Cloudy
        ,Coffee,CoffeeCup,Coin,ColdDrink,Collection,CollectionTag,Comment
        ,Compass,Connection,Coordinate,CopyDocument,Cpu,CreditCard,Crop
        ,DArrowLeft,DArrowRight,DCaret,DataAnalysis,DataBoard,DataLine,Delete
        ,DeleteFilled,DeleteLocation,Dessert,Discount,Dish,DishDot,Document
        ,DocumentAdd,DocumentChecked,DocumentCopy,DocumentDelete,DocumentRemove,Download,Drizzling
        ,Edit,EditPen,Eleme,ElemeFilled,ElementPlus,Expand,Failed
        ,Female,Files,Film,Filter,Finished,FirstAidKit,Flag
        ,Fold,Folder,FolderAdd,FolderChecked,FolderDelete,FolderOpened,FolderRemove
        ,Food,Football,ForkSpoon,Fries,FullScreen,Goblet,GobletFull
        ,GobletSquare,GobletSquareFull,Goods,GoodsFilled,Grape,Grid,Guide
        ,Headset,Help,HelpFilled,Hide,Histogram,HomeFilled,HotWater
        ,House,IceCream,IceCreamRound,IceCreamSquare,IceDrink,IceTea,InfoFilled
        ,Iphone,Key,KnifeFork,Lightning,Link,List,Loading
        ,Location,LocationFilled,LocationInformation,Lock,Lollipop,MagicStick,Magnet
        ,Male,Management,MapLocation,Medal,Menu,Message,MessageBox
        ,Mic,Microphone,MilkTea,Minus,Money,Monitor,Moon
        ,MoonNight,More,MoreFilled,MostlyCloudy,Mouse,Mug,Mute
        ,MuteNotification,NoSmoking,Notebook,Notification,Odometer,OfficeBuilding,Open
        ,Operation,Opportunity,Orange,Paperclip,PartlyCloudy,Pear,Phone
        ,PhoneFilled,Picture,PictureFilled,PictureRounded,PieChart,Place,Platform
        ,Plus,Pointer,Position,Postcard,Pouring,Present,PriceTag
        ,Printer,Promotion,QuestionFilled,Rank,Reading,ReadingLamp,Refresh
        ,RefreshLeft,RefreshRight,Refrigerator,Remove,RemoveFilled,Right,ScaleToOriginal
        ,School,Scissor,Search,Select,Sell,SemiSelect,Service
        ,SetUp,Setting,Share,Ship,Shop,ShoppingBag,ShoppingCart
        ,ShoppingCartFull,Smoking,Soccer,SoldOut,Sort,SortDown,SortUp
        ,Stamp,Star,StarFilled,Stopwatch,SuccessFilled,Sugar,Suitcase
        ,Sunny,Sunrise,Sunset,Switch,SwitchButton,TakeawayBox,Ticket
        ,Tickets,Timer,ToiletPaper,Tools,Top,TopLeft,TopRight
        ,TrendCharts,Trophy,TurnOff,Umbrella,Unlock,Upload,UploadFilled
        ,User,UserFilled,Van,VideoCamera,VideoCameraFilled,VideoPause,VideoPlay
        ,View,Wallet,WalletFilled,Warning,WarningFilled,Watch,Watermelon
        ,WindPower,ZoomIn,ZoomOut        
    },
    data:function(){
        return {
            isCollapse:false,
            menuTree:[]
        }
    },
    computed:{
        onRoutes() {
            return this.$route.path.replace('/', '');
        }
    },
    methods:{
        onMenuClick(menuItem){
            if(menuItem.isUrl == 0){
                this.$router.push({
                    path: menuItem.id,
                    meta:{keepAlive:true}
                });
            }else { 
                // let param = '';
                // let i = 0;
                // for(let key in menuItem.param){
                //     param += key + "=" + menuItem.param[key] + "&";
                // }
                // param = param.substring(0,param.length - 1);
                // let url = [menuItem.url,param].join('?');
                window.open(menuItem.url);
            }
        },
        onSingleMenuClick(url) {
            this.$router.push({
                path: url,
                meta:{keepAlive:true}
            });
        },
        //下载菜单信息
        downloadMenu(){
            //从本地加载数据创建菜单
            let menu = UserStorage.getMenu();
            if(menu != null && menu.length > 0){
                this.menuTree = menu;
                return;
            }
            asyncPostLoading(this.$userURL + "/user/queryMenu",{},res => {
                if(res.data.head.st != 0){
                    this.$message.error(res.data.head.msg);
                    return;
                }
                this.menuTree = [];
                this.menuTree = res.data.body.tree;
                UserStorage.setMenu(this.menuTree);

                let menuList = res.data.body.list;
                let localRoute = RouteData.getLocalRoute(); 
                let homeRoute = {};
                for(let n = 0;n<localRoute.length;n++){
                    if(localRoute[n].name == 'home'){
                        homeRoute = localRoute[n];
                        break;
                    }
                }
                //循环menu list，创建router,并且保存按钮权限  
                let butMap = {};
                let butSubMap = {};
                //0导航，1左侧父级，2菜单，3按钮
                for(let i = 0;i<menuList.length;i++){ 
                    //按钮
                    if(menuList[i].type == 3){
                        if(butMap.hasOwnProperty(menuList[i].parentCode)){
                            butSubMap = butMap[menuList[i].parentCode];
                        }else{
                            butSubMap = {};
                            butMap[menuList[i].parentCode] = butSubMap;
                        }
                        butSubMap[menuList[i].permission] = 1;
                        continue;
                    }
                    //文件
                    if(menuList[i].pageUrl != null && menuList[i].pageUrl != ''){ 
                        if(menuList[i].type == 0) { 
                            localRoute.push(menuList[i]);
                        }
                        else{ 
                            homeRoute.children.push(menuList[i]);
                        } 
                    }
                }
                UserStorage.setBtnPermission(butMap);
                UserStorage.setRouteInfo(localRoute); 

                let routes = RouteData.createRoutes();
                for(let o = 0;o<routes.length;o++){
                    this.$router.addRoute(routes[o]);
                }
                
            },err => {
                this.$ElMessage.error(err); 
            });
        } 
    },
    created(){
       this.downloadMenu();
    }
}
</script>


<style scoped>
/* icon的大小 */
.icons{
    vertical-align: middle;
    margin-right: 12px;
    width: var(--el-menu-icon-width);
    text-align: center;
    font-size: 18px;

    --color: inherit;
    height: 1em;
    width: 1em;
    line-height: 1em;
}

/* 菜单的外部div */
.left-menu{
    width: 200px;
    position: absolute;
    top: 70px;
    height: calc(100% - 70px);
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow-y: scroll;
}

/* *****************************滚动条样式********************************* */
::-webkit-scrollbar{
    width:5px;
    /* height:5px; */
    background-color:#F5F5F5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track{
    /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
    /* border-radius:10px; */
    background-color:#F5F5F5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb{
    border-radius:2px;
    /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3); */
    background-color:rgba(2, 153, 181,0.8);
    
} 
/* 鼠标悬停 */
::-webkit-scrollbar-thumb:hover{
    cursor: pointer;
}

.left-menu >>> .single-view.el-menu-item {
    border-bottom: 1px solid rgba(96, 98, 102, 0.5);
    overflow: hidden;
    padding-left:10px !important;
    font-size: 12px !important;
    height: 40px;
    
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

/* 整个菜单的大小样式 */ 
.left-menu >>> .el-menu{
    font-size: 10px;
    margin-left:15px;
    margin-top: 10px;
    margin-right: 15px;
    width:calc(100% - 30px) !important;
    border: 0;
    height: calc(100% - 10px);
}
/* 子菜单块的样式 */
.left-menu >>> .el-sub-menu ul{
    font-size: 10px;
    margin-left:0px;
    margin-top: 0px;
    margin-right: 0px;
    width:100% !important;
    border: 0;  
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}
/* 子菜单项 */
.left-menu >>> .el-menu-item{
    width:100% !important;
    min-width: unset !important;
    height: 40px;

    font-family: 'PingFang SC';
    font-style: normal;
    /* font-weight: 600; */
    font-size: 14px;
}
/* 子菜单鼠标移动效果 */
/* .el-menu >>> .el-menu-item:hover{
    outline: 0 !important;
    color: #2E95FB !important;
    background: linear-gradient(270deg, #F2F7FC 0%, #FEFEFE 100%)!important;
} */
.el-menu .el-menu-item.is-active {
    color: var(--el-color-primary);
    background: linear-gradient(270deg, #F2F7FC 0%, #FEFEFE 100%)!important;
}
/* 子菜单鼠标移动效果 */
/* .el-submenu .el-submenu__title:hover {
  color: #2E95FB !important;
  background: linear-gradient(270deg, #F2F7FC 0%, #FEFEFE 100%)!important;
} */


.left-menu >>> .el-sub-menu__title{
    border-bottom: 1px solid rgba(96, 98, 102, 0.5);
    overflow: hidden;
    padding-left:10px !important;
    font-size: 12px !important;
    height: 40px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}
.left-menu >>> .el-menu-item{
    border-bottom: 1px solid rgba(184, 186, 190, 0.5);
    margin:0 !important;

    font-family: 'PingFang SC';
    font-style: normal;
    /* font-weight: 600; */
    font-size: 12px;
}
/* 改变elementui 侧边栏移入颜色 */
/* .left-menu >>> .el-menu-item:hover {
  background: #4170FF !important;
  color: #fff !important;
} */
.left-menu >>> .el-submenu__title:hover {
  background: #0299B5 !important;
  color: #fff !important;
}
.left-menu >>> .el-menu-item.is-active {
  background: #0299B5 !important;
  color: #fff !important;
}
.left-menu >>> .el-submenu__title.is-active {
  background: #0299B5 !important;
  color: #fff !important;
}
</style>